import Popup from 'reactjs-popup';
import Close from '/components/ui/Close';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
`;

const Modal = ({
  padding,
  maxWidth,
  closeColor,
  closeStyle,
  children,
  ...rest
}) => (
  <Popup
    modal
    closeOnDocumentClick
    overlayStyle={{
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    }}
    contentStyle={{
      padding: padding ? padding : '10px',
      width: '100%',
      backgroundColor: 'transparent',
      maxWidth: maxWidth ? maxWidth : '600px',
      maxHeight: 'calc(100% - 60px)',
      border: 0,
    }}
    {...rest}
  >
    {close => (
      <Wrapper>
        <Close
          className="button"
          style={closeStyle}
          backgroundColor={closeColor}
          onClick={() => {
            close();
          }}
        />
        {children}
      </Wrapper>
    )}
  </Popup>
);

export default Modal;
